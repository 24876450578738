@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-sm;
  }
  :focus {
    @apply outline-none;
  }
}

html {
  font-family: 'Lato', 'sans-serid';
}